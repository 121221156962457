import React from "react";
import { graphql, Link } from "gatsby";
import { FilmProps, NavigatedToState } from "src/types";
import Layout from "../components/layout";
import { format } from "date-fns";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { formatDate } from "../utils/formatDate";
import SEO from "../components/seo";
import ArticleCard from "../components/articleCard";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Badge from "../components/Badge";
import Comments from "../components/Comments";
import GoBack from "../components/GoBack";
import { useGlobalStyles } from "../style";
import Like from "../components/Like";

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  text: {
    alignText: "justify"
  },
  grid: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between"
    }
  },
  divider: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  img: {
    [theme.breakpoints.up("sm")]: {
      width: 200,
      float: "right",
      marginTop: 20
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 100
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto"
    },
    margin: theme.spacing(0, 0, 2, 2)
  },
  space: { height: theme.spacing(2) }
}));

export default function Template({
  data: {
    markdownRemark: review,
    relatedArticles,
    film: {
      nodes: [film]
    }
  },
  location
}: FilmProps & NavigatedToState) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  return (
    <Layout>
      <SEO
        title={review.frontmatter.title + " Review"}
        description={review.excerpt}
        image={review.frontmatter.poster.childImageSharp.fluid.src}
      />
      <GoBack navigatedTo={location?.state?.navigatedTo} />
      <Divider className={classes.divider} />
      {review.frontmatter.spoilers ? (
        <Alert className={classes.divider} severity="warning">
          This review contains spoilers.
        </Alert>
      ) : null}
      <div className={`${classes.grid} badgeWrapper`}>
        <Typography variant="h4" component="h1">
          {review.frontmatter.title}
        </Typography>
        <Hidden xsDown>
          <MuiLink color="textSecondary" className={classes.link}>
            {film.directors
              .split(",")
              .map(director => director.trim())
              .map(director => (
                <Link
                  key={director}
                  className={classes.link}
                  state={{ navigatedTo: true }}
                  to={`/cast-and-crew/${Array.from(director.toLowerCase())
                    .map(c => (c === " " ? "-" : c))
                    .map(c => (c === "." ? "" : c))
                    .reduce((a, b) => a + b, "")}`}
                >
                  {director}
                </Link>
              ))}
          </MuiLink>
        </Hidden>
        <Hidden xsDown>
          {format(new Date(film.releaseDate), "dd/MM/")}
          <MuiLink color="textSecondary" style={{ flexGrow: 1 }}>
            <Link
              state={{ navigatedTo: true }}
              to={`/year/${new Date(film.releaseDate).getFullYear()}`}
            >
              {new Date(film.releaseDate).getFullYear()}
            </Link>
          </MuiLink>
        </Hidden>

          <div className="rating">
            <Badge rating={film.rating} />
          </div>
        </div>
      <Img
        className={classes.img}
        fluid={review.frontmatter.poster.childImageSharp.fluid}
      />
      <Hidden smUp>
        <MuiLink color="textSecondary" className={classes.link}>
          {film.directors
            .split(",")
            .map(director => director.trim())
            .map(director => (
              <Link
                key={director}
                className={classes.link}
                to={`/cast-and-crew/${Array.from(director.toLowerCase())
                  .map(c => (c === " " ? "-" : c))
                  .map(c => (c === "." ? "" : c))
                  .reduce((a, b) => a + b, "")}`}
              >
                {director}
              </Link>
            ))}
        </MuiLink>
        {format(new Date(film.releaseDate), "dd/MM/")}
        <MuiLink color="textSecondary" style={{ flexGrow: 1 }}>
          <Link to={`/year/${new Date(film.releaseDate).getFullYear()}`}>
            {new Date(film.releaseDate).getFullYear()}
          </Link>
        </MuiLink>
      </Hidden>

      <div style={{ height: 20 }}></div>

      <div
        className={`${classes.text} ${globalClasses.article} article film-review`}
        dangerouslySetInnerHTML={{ __html: review.html }}
      />
      <div className={classes.space} />
      <div style={{ display: "flex", marginBottom: 30 }}>
        <Typography variant="body2" style={{ marginRight: 20 }}>
          Posted by {review.frontmatter.author} on{" "}
          {formatDate(review.frontmatter.date)}
        </Typography>
        {typeof window !== "undefined" ? (
          <Like path={review.frontmatter.path} />
        ) : null}
        <MuiLink
          style={{ marginLeft: 20 }}
          href={`https://letterboxd.com${review.frontmatter.path}`}
        >
          letterboxd
        </MuiLink>
        <MuiLink
          style={{ marginLeft: 20 }}
          href={`https://imdb.com/title/${film.imdb}`}
        >
          imdb
        </MuiLink>
      </div>
      {relatedArticles.edges[0] ? (
        <Typography variant="h6">Related</Typography>
      ) : null}
      <div style={{ display: "flex" }}>
        {relatedArticles.edges.map((article: any) => (
          <ArticleCard
            key={article.node.frontmatter.path}
            article={article.node}
          />
        ))}
      </div>
      {typeof window !== "undefined" ? (
        <Comments path={review.frontmatter.path} />
      ) : null}
    </Layout>
  );
}

export const postQuery = graphql`
  query FilmByPath($path: String!, $imdb: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      id
      excerpt
      frontmatter {
        path
        title
        author
        date
        spoilers
        poster {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    relatedArticles: allMarkdownRemark(filter: { fileAbsolutePath:{regex:"/articles/.*\\.md$/"}, frontmatter:{ features: {eq:$path} } }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            path
            date
            author
            title
          }
        }
      }
    }
    film: allFilmCsv(filter: { imdb: { eq: $imdb } }) {
      nodes {
        eTitle
        releaseDate
        imdb
        directors
        rating
      }
    }
  }
`;
